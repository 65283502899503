/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import ms from 'ms';
import Iframe from './Iframe';
import {
  MULTIPLE_TYPE,
  WORK_IN_PROGRESS_TYPE,
  WELCOME_TYPE,
  PICKER_KIBANA_APPLICATIONS,
} from '../../services/constants';

import ApplicationsMenu from './ApplicationsMenu';
import WorkInProgress from './WorkInProgress';
import Welcome from './Welcome';
import { refreshTimeInterval } from '../../redux/actions/time.actions';

export function generateUrl(
  selectedApplication,
  from,
  to,
  refreshStatus,
  refreshInterval,
  ref
) {
  if (!selectedApplication?.url) return null;
  // TODO: when application type is available, check application type instead of URL contents
  let url;

  try {
    // Check if current application is the same
    if (
      ref.current.title === selectedApplication.name &&
      ref?.current?.contentWindow?.location?.href !== 'about:blank'
    ) {
      url = ref.current.contentWindow.location.href;
    } else {
      url = selectedApplication.url;
    }
  } catch {
    url = selectedApplication.url;
  }

  if (
    !PICKER_KIBANA_APPLICATIONS.every(
      (v) => !selectedApplication.url.includes(v)
    )
  ) {
    const regexTimeKibana = /time:\([^)]*/;
    const formatTimeKibana = 'YYYY-MM-DDTHH:mm:ss';
    const generatedTimeFilterKibana = `time:(from:'${from.format(
      formatTimeKibana
    )}',to:'${to.now ? 'now' : to.format(formatTimeKibana)}'`;
    const regexRefreshKibana = /refreshInterval:\([^)]*/;
    const refreshStatusKibana = refreshStatus ? 'f' : 't';
    const generatedRefreshKibana = `refreshInterval:(pause:!${refreshStatusKibana},value:${refreshInterval}`;

    return url
      .replace(regexTimeKibana, generatedTimeFilterKibana)
      .replace(regexRefreshKibana, generatedRefreshKibana);
  }

  url = new URL(url ?? selectedApplication.url);

  if (selectedApplication?.useTimePicker) {
    const timeFormat = selectedApplication?.configuration?.timeFormat ?? 's';
    const formattedFrom = from * ms(`1${timeFormat}`);
    const formattedTo = (to.now ? dayjs() : to) * ms(`1${timeFormat}`);

    url.searchParams.set('from', formattedFrom);
    url.searchParams.set('to', formattedTo);
  }

  if (selectedApplication?.refresh?.refreshBy === 'application') {
    url.searchParams.set(
      'refreshInterval',
      refreshStatus ? refreshInterval : 0
    );
  }

  return url.href;
}

function AppFrame(props) {
  const {
    selectedApplication,
    from,
    to,
    refreshStatus,
    refreshInterval,
    // eslint-disable-next-line no-shadow
    refreshTimeInterval,
  } = props;

  const ref = useRef(null);
  const generatedUrl = generateUrl(
    selectedApplication,
    from,
    to,
    refreshStatus,
    refreshInterval,
    ref
  );

  useEffect(() => {
    let intervalId;
    if (refreshStatus && selectedApplication?.refresh?.refreshBy === 'portal') {
      intervalId = setInterval(refreshTimeInterval, refreshInterval);
    }
    return () => clearInterval(intervalId);
  }, [
    refreshStatus,
    refreshInterval,
    selectedApplication?.refresh?.refreshBy,
    refreshTimeInterval,
  ]);

  if (selectedApplication.type === WORK_IN_PROGRESS_TYPE) {
    return <WorkInProgress />;
  }
  if (selectedApplication.type === WELCOME_TYPE) {
    return <Welcome />;
  }
  if (selectedApplication.type === MULTIPLE_TYPE) {
    return <ApplicationsMenu />;
  }
  return (
    <Iframe ref={ref} name={selectedApplication.name} url={generatedUrl} />
  );
}

AppFrame.propTypes = {
  selectedApplication: PropTypes.object.isRequired,
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  refreshStatus: PropTypes.bool.isRequired,
  refreshInterval: PropTypes.number.isRequired,
  refreshTimeInterval: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  refreshTimeInterval: () => dispatch(refreshTimeInterval()),
});

export default connect(null, mapDispatchToProps)(AppFrame);
